<template>
  <div class="py-4">
    <h1 class="text-2xl font-semibold text-gray-900 mb-4">
      <router-link to="/dashboard"> Dashboard </router-link>
      <span class="text-sm">/ History </span>
    </h1>

    <div
      v-if="histories.length == 0"
      class="border-gray-500 text-center text-gray-600 border-2 py-2 text-lg font-bold"
    >
      No history logged yet.
    </div>

    <template v-else>
      <div
        class="font-bold text-lg flex flex-row text-center justify-between text-gray-600 mt-10"
      >
        <div class="border-gray-500 border-2 w-full py-2">MONTH</div>
        <div
          class="border-gray-500 border-t-2 border-r-2 border-b-2 w-full py-2"
        >
          MASS TEXT CAMPAIGNS
        </div>
        <div
          class="border-gray-500 border-t-2 border-r-2 border-b-2 w-full py-2"
        >
          MESSAGES SENT
        </div>
        <div
          class="border-gray-500 border-t-2 border-r-2 border-b-2 w-full py-2"
        >
          MESSAGES RECEIVED
        </div>
        <div
          class=" border-gray-500 border-t-2 border-r-2 border-b-2 w-full py-2"
        >
          TOTAL
        </div>
      </div>
      <div
        class="flex flex-row justify-between text-center"
        v-for="(history, index) in histories"
        :key="history.id"
        :class="index % 2 != 0 ? 'bg-gray-300' : ''"
      >
        <div
          class="border-gray-500 border-r-2 border-b-2 border-l-2 w-full py-2 font-bold text-lg text-gray-600"
        >
          {{ history.entry_date && transformDate(history.entry_date.seconds) }}
        </div>
        <div
          class="border-gray-500  border-r-2 border-b-2 w-full py-2 text-base"
        >
          {{ history.mass_text_campaigns }}
        </div>
        <div class="border-gray-500  border-r-2 border-b-2 w-full py-2">
          {{ history.messages_sent }}
        </div>
        <div class="border-gray-500 border-r-2 border-b-2 w-full py-2">
          {{ history.messages_received }}
        </div>
        <div class=" border-gray-500 border-r-2 border-b-2 w-full py-2">
          {{
            Number(history.messages_received) + Number(history.messages_sent)
          }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
const firebase = require("../../firebaseConfig");
const { DateTime } = require("luxon");

export default {
  name: "DashboardHistory",
  data: () => ({
    getCurrentDate: {
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    },
    getStringMonth: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    histories: [],
  }),
  methods: {
    getHistories() {
      firebase.db
        .collection("businesses")
        .doc(this.$route.params.business_id)
        .collection("history")
        .orderBy("entry_date", "desc")
        .onSnapshot((docs) => {
          this.histories = [];
          docs.forEach((doc) => {
            this.histories.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
      console.info("histories", this.histories);
    },
    transformDate(seconds) {
      let date = new Date(0);
      date.setUTCSeconds(seconds);
      const ISOdate = date.toISOString();

      return DateTime.fromISO(ISOdate)
        .setZone("America/Detroit")
        .toFormat("LLLL yyyy");
    },
  },
  mounted() {
    this.getHistories();
  },
};
</script>
